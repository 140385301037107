.defaultStyle {
  position: fixed;
  top: var(--ds-spacing-3xs);
  width: 95%;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
  flex-direction: column-reverse;
  align-items: center;
  z-index: 99999;

  box-sizing: border-box;
  padding: 0 var(--ds-spacing-3xs);

  @media (--ds-breakpoint-sm) {
    width: max-content;
    padding: none;
    margin: none;
    top: var(--ds-spacing-sm);
    right: var(--ds-spacing-sm);
    left: unset;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
