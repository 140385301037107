.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid var(--ds-color-brand-bright-yellow);
  padding: var(--ds-spacing-xs);
  background-color: var(--ds-color-neutral-white);
  @media (--ds-breakpoint-sm) {
    max-width: 375px;
  }
  z-index: 3;
}

.top-position {
  top: var(--ds-spacing-3xl);
}

.logo-container {
  max-width: var(--ds-size-xl);
}

.logo-container img {
  aspect-ratio: initial;
}
