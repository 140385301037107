@import './css/index.css';

/* this will change styling for app-shop and app-checkout.
Styled-components v5 compatible. see [SET-5361](https://github.com/LEGO/octan/pull/7464) for more details */
html {
  min-height: 100%;
  height: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body {
  height: 100%;
  width: 100%;
  min-height: 100%;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -webkit-overflow-scrolling: touch;
  color: #2c2c2c;
  background: #fff;
}

#root {
  height: 100%;
}

html[lang='ko'] > body {
  font-family: 'Noto Sans KR', sans-serif;
}

html[lang='ja'] > body {
  font-family: 'Noto Sans JP', sans-serif;
}

html[lang='zh'] > body {
  font-family: 'Noto Sans SC', sans-serif;
}

html:not([lang='ko']):not([lang='zh']):not([lang='ja']) > body {
  font-family: 'Cera Pro', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  color: #006db7;
  text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
  a:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
  display: inline-block;
  vertical-align: middle;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

ul li,
ol li {
  list-style-type: none;
}

dd {
  margin: 0;
}

hr {
  height: 1px;
  background-color: #e0e0e0;
  border: none;
}

button,
optgroup,
select,
textarea,
address {
  color: inherit;
  font: inherit;
}

address {
  font-style: inherit;
}

strong {
  font-weight: 500;
}

em {
  font-weight: 500;
}

button {
  border: 0;
  padding: 0;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
}

input[type='search'] {
  box-sizing: border-box;
}

*::-ms-clear,
*::-ms-reveal {
  display: none;
}

*:focus {
  outline: none;
  box-shadow: none;
}

.a11y-mode input:focus,
.a11y-mode select:focus,
.a11y-mode button:focus,
.a11y-mode a:focus {
  outline: none;
  box-shadow: 0 0 0 var(--ds-border-width-sm, 2px)
    var(--ds-color-focused-default, #0dade4);
}

.a11y-mode input[type='search'] {
  outline: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.markup ul,
.markup ol {
  display: block;
  margin: var(--ds-spacing-2xs) 0;
  padding-left: var(--ds-spacing-sm);
}

.markup li {
  margin-bottom: var(--ds-spacing-4xs);
}

.markup ul li {
  list-style-type: disc;
}

.markup ol li {
  list-style-type: decimal;
}

/* These are default values that will be overwritten by a single
dynamic value when the Header component mounts */
:root {
  --header-height: 3.375rem;

  @media (--breakpoint-medium-min) {
    --header-height: 8.125rem;
  }
}

/* 
 * Content page minimum height
 * The magic numbers come from the shopper theme formula:
 * Desktop: Math.pow(2, 2.5) * 12) / 16 = 4.24rem
 * Mobile: Math.pow(2, 2.3) * 12) / 16 = 3.69rem
 */
.main--content {
  min-height: calc(85vh - 3.69rem);
  width: 100%;
  @media (--breakpoint-medium-min) {
    min-height: calc(100vh - 4.24rem);
  }
}

/* Prevent <i> tags from using Cera Pro Light */
i {
  font-family: 'Cera Pro Italic', sans-serif;
}
