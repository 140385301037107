.dropdown {
  display: flex;
  position: absolute;
  flex-direction: column;
  z-index: 2;
  top: 0;
  align-items: baseline;
  gap: var(--ds-spacing-3xs);
  background-color: var(--ds-color-layer-default);
  border-radius: var(--ds-border-radius-md);
  box-shadow: var(--ds-shadow-deep-md);
  padding: var(--ds-spacing-3xs);
  width: 13rem;
}

.LEFT {
  right: var(--ds-spacing-xl);
}

.RIGHT {
  left: var(--ds-spacing-xl);
}

.menu {
  width: 100%;
  overflow-y: auto;
  max-height: 10rem;
}

.menuitem {
  padding-right: var(--ds-spacing-3xs);
  padding-left: var(--ds-spacing-4xs);

  &:hover {
    background-color: var(--ds-color-layer-informative-subdued);
  }

  &:not(:last-child) {
    margin-bottom: var(--ds-spacing-3xs);
  }
}

.limitMessage {
  composes: ds-body-xs-regular from global;
  color: var(--ds-color-text-subdued);
}
