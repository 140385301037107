.utilityListItem {
  margin: 0 var(--ds-spacing-sm);
  border-bottom: 1px solid var(--ds-color-border-subdued);

  & a,
  & button {
    display: flex;
    color: var(--ds-color-text-default);
    padding: var(--ds-spacing-2xs) 0;
    width: 100%;
  }
}
