.listItem {
  display: flex;
  margin: 0 var(--ds-spacing-xs);
  border-bottom: 1px solid var(--ds-color-border-subdued);

  & a {
    color: var(--ds-color-text-default);
  }
}

.listButton {
  padding: var(--ds-spacing-2xs) 0;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

.rightColumn {
  display: flex;
  gap: var(--ds-spacing-2xs);
  align-items: center;
}
