.inputSection {
  display: flex;
  margin-top: var(--ds-spacing-2xs);
  gap: var(--ds-spacing-xs);
  flex-direction: column;

  @media (--ds-breakpoint-sm) {
    flex-direction: row;
  }

  @media (--ds-breakpoint-md) {
    min-width: 32rem;
  }
}

.textField {
  flex: 1;
}

.button {
  @media (--ds-breakpoint-sm) {
    align-self: flex-end;
  }
}

.errorWrapper {
  margin-top: var(--ds-spacing-2xs);
}
