.modalContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: var(--ds-spacing-sm) var(--ds-spacing-lg);

  background-size: cover;
  background-repeat: no-repeat;

  @media (--ds-breakpoint-sm) {
    background-image: url('./assets/signInModalBg.png');
  }
}

.modal {
  @media (--ds-breakpoint-lg) {
    min-width: 25rem;
  }
}
