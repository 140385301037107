.addOptionalDescription {
  margin-top: var(--ds-spacing-lg);
  max-width: 25rem;
}

.blackText {
  color: var(--ds-color-text-default);
}

.orderStatus {
  color: var(--ds-color-text-primary);
}

.orderStatusWrapper {
  display: flex;
  border-top: 1px solid var(--ds-color-border-neutral-subdued);
  width: 100%;
  padding: var(--ds-spacing-sm) 0;
  justify-content: center;
  align-items: center;
  gap: var(--ds-spacing-xs);
}

.registerLink {
  padding: var(--ds-spacing-xs);
  text-decoration: underline;
  color: var(--ds-color-text-primary);
}

.registerWrapper {
  margin: 0 0 var(--ds-spacing-ms);
  display: flex;
  align-items: baseline;
  padding-top: var(--ds-spacing-sm);
  padding-bottom: var(--ds-spacing-lg);
}

.editAccountLoggedIn {
  padding: var(--ds-spacing-xs);
  color: var(--ds-color-text-primary);
}

.editAccountLoggedIn:hover {
  text-decoration: none;
}

.email {
  padding-left: var(--ds-spacing-xs);
  padding-bottom: var(--ds-spacing-sm);
}

.image {
  width: 9.188rem;
}

.login {
  border-radius: 999px;
  border: 2px solid var(--ds-color-text-primary);
  padding: var(--ds-spacing-2xs);

  width: 100%;
}

.login:hover {
  text-decoration: none;
  background-color: var(--ds-color-layer-primary-enabled);
}

.logout {
  margin: 0 auto;
  border-radius: 999px;
  border: 1px solid var(--ds-color-text-primary);
  padding: var(--ds-spacing-xs);
  background-color: var(--ds-color-text-primary);
  color: var(--ds-color-text-inverse);
}

.logout:hover {
  text-decoration: none;
  background-color: var(--ds-color-text-inverse);
  color: var(--ds-color-text-static-dark);
}
.title {
  margin: var(--ds-spacing-lg) auto;
  line-height: var(--ds-spacing-md);
}
