.menuPanel {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  transition: transform 500ms;
}

.left {
  transform: translateX(-100%) translateZ(0);
  visibility: hidden;
}

.center {
  transform: translateZ(0);
}

.right {
  transform: translateX(100%) translateZ(0);
  visibility: hidden;
}
