.mainMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.highlightedListItem {
  display: flex;
  margin: 0 var(--ds-spacing-xs);
  padding: var(--ds-spacing-2xs) 0;
  border-bottom: 1px solid var(--ds-color-border-subdued);

  & a {
    color: var(--ds-color-brand-bright-red);
  }
}

.kidsWrapper {
  padding: var(--ds-spacing-2xs) var(--ds-spacing-xs);
  background: var(--ds-color-layer-subdued);
  display: flex;
  align-items: center;
  gap: var(--ds-spacing-xs);

  & a {
    /* these are hard-coded as the current design doesn't match with Skroll styles */
    background-color: #abd9ff;
    color: var(--ds-color-text-default);
    box-shadow: 0px 3px 0px 0px #8cb8ec;
    width: 65%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--ds-spacing-3xs) 0;
    gap: var(--ds-spacing-3xs);
  }
}
